import { IndexType } from "../enums";
import { ArtistModel } from "../model";

export class IndexInfo {
    indexType = IndexType.Any;         //  для навигации
    tokenName? = "";        // должно совпадать с названием CurrencyType на беке 
    title = "";
    distribution = 0; 
    distributionStr = ""; 
    distributionInDays = 0;
    apy = 0;                //  в процентах
    riskLevel = RiskLevelEnum.Any;
    allTimeReturn = 0;      //  в процентах
    price? = 0;
    rewards? = 0;
    textContent? = "";       // тексовая информация о индексе
    shortTextContent? = "";  
    isAvailable = false;     // доступно или нет
    minInvestment = 0;
    interestRate = 0;
    bgImageUrl = "";
    bgImageMobileUrl = "";
    artistList: ArtistModel[] = [];
    expectedReturns: ExpectedReturnsModel = new ExpectedReturnsModel();
    isPresaleIndex = false;
    artist? = "";
    investorsCount? = 0;
    daysLeft? = 0;
    totalSales: number;
    targetSales: number;   
    percentOfTarget: number;
}

export class IndexInfoByTypeDict {
    [key: number]: IndexInfo
}

export enum RiskLevelEnum {
    Any = -200,
    LowRisk = 1
}

export class ExpectedReturnsModel {
    percentPerYear = 0;
    investmentHorizon: string = "";
    investAmount = 0;
    getAmount = 0;
}