
<h1 class="auth-bg-title text-center text-sm-start">{{"Welcome" | lang}}</h1>
<div class="card-body">
  <div class="login-header align-center-900 text-center text-sm-start">
    <label>{{"Build wealth with music royalties" | lang}}</label>
  </div>
  <form class="theme-form" [formGroup]="loginForm">
    <div class="form-group">
      <input id="email-input-field" type="email" class="form-control" formControlName="email" type="email" placeholder="{{'Email' | lang}}" required="" autofocus="">
      <div id="email-error" *ngIf="elemIsInvalid(loginForm.controls.email)" class="text text-danger mt-1">
        {{textErrorStr(loginForm.controls.email) | lang}}
      </div>
    </div>

    <div class="form-group">
      <div class="input-pass">
        <div style = "position: relative;">
            <input id="password-input-field" placeholder="{{'Password' | lang}}" [type]="!showPass? 'password' : 'text'" class="form-control" formControlName="password">
            <!-- <img (click)= "hideOrShowPassword()" [src]="showPass? '/assets/images/auth/icon_eye_closed.svg' : '/assets/images/auth/icon_eye.svg'"
              style = "position: absolute;  top: 13px; right: 13px; height: 22px; opacity: 0.2;" draggable="false"> -->
        </div>
      </div>
      <div *ngIf="elemIsInvalid(loginForm.controls.password)" id="password-error" class="text text-danger mt-1">
        {{textErrorStr(loginForm.controls.password) | lang}}
      </div>
    </div>
    <div *ngIf="!!errorText" id="error-emailpassword" class="text text-danger text-center">
      <img *ngIf="errorText != systemErrorText" src="assets/images/danger.svg"/>&nbsp;{{errorText | lang}}
    </div>

    <div [hidden]="!showReCAPTCHA" class="mt-3" id="captchaField" style="text-align: -webkit-center;"></div>

    <div class="form-group pt-2">
        <button id="login-button" class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader" (click)="login()">
          {{"Sign In" | lang}}
        </button>
    </div>

    <div class="desktop-show auth-card-footer">
      {{"Forgot your password" | lang}}?
      <a id="reset-button" class="c-green" href="javascript:void(0)"
        (click)="navigateTab('resetPass')">{{"Reset password" | lang}}</a>
    </div>

    <div class="desktop-show auth-card-footer">
      {{"Don't have an account yet" | lang}}?
      <a id="signup-button" class="c-green" href="javascript:void(0)"
        (click)="navigateTab('reg')">{{"Sign Up" | lang}}</a>
    </div>
  </form>
</div>
