export class BuyPostRequest {
    amount: number;
    gasPrice: number;
    gasLimit: number;
    currencyType: number;
    currency: number;
    // ProgramId: number;
    actualRateId: number;
    isSetToken:boolean;
    entryFeeAmount: number;
    promocodeId: number;
    useReducedGas: boolean;
    compensationCoef: number;
    uuid: string;
    buyTokenAmount: number;
    isPresale: boolean;
  }