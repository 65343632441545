import { Injectable, EventEmitter } from "@angular/core";
import { SettingMasterModel } from "../model/settingMaster.model";
import { ApiService } from "./api.service"
import { IResponse } from "../interfaces"
import { PromocodeModel } from "../model";
import { PromocodePostRequest } from "../model/request/promocodePostRequest";
import { IndexType } from "../enums";
import { FAQModel } from "../model/faq.model";
import { ClientSettingsModel } from "../model/clientSettings.model";

const apisettingUrl = 'api/setting';
@Injectable({
    providedIn: 'root'
})
export class SettingService {

    constructor(private api: ApiService) { }
 
    public getLastUpdate(nameSubstring: string): Promise<IResponse<SettingMasterModel[]>> {
        return this.api.get<SettingMasterModel[]>(apisettingUrl + "/getlastupdate/"+ nameSubstring).toPromise();
    }

    public updateAddressBook(): Promise<IResponse<SettingMasterModel[]>> {
        return this.api.get<SettingMasterModel[]>(apisettingUrl + "/updateAddressBook").toPromise();
    }
    
    public getAllSetting(lang: string) : Promise<IResponse<SettingMasterModel[]>> {
        return this.api.get<SettingMasterModel[]>(apisettingUrl + "/all/" + lang).toPromise();
    }

    public getSettingValue(key: string) : Promise<IResponse<string>> {
      return this.api.get<string>(apisettingUrl + "/getsetting/" + key).toPromise();
    }

    public getVideoUrlForToken(indexType: IndexType) : Promise<IResponse<SettingMasterModel>> {
      let localization = 'en';
      return this.api.get<SettingMasterModel>(apisettingUrl + "/getVideoUrlForToken/" + indexType + "/" +  localization).toPromise();
    }

    public getTwitterNickname() : Promise<IResponse<SettingMasterModel>> {
        return this.api.get<SettingMasterModel>(apisettingUrl + "/getTwitterNickname").toPromise();
      }

    public updateSetting(model: SettingMasterModel[]) : Promise<IResponse<boolean>> {
        return this.api.post<boolean>(apisettingUrl + "/update", model).toPromise();
    }

    public getPromocodes() : Promise<IResponse<PromocodeModel[]>> {
        return this.api.get<PromocodeModel[]>(apisettingUrl + "/getPromocodes").toPromise();
    }

    public updatePromocodes(promocodesToChange: PromocodePostRequest): Promise<IResponse<boolean>> {
        return this.api.post<boolean>(apisettingUrl + "/updatePromocodes", promocodesToChange).toPromise();
    }

    public getPromocodeByCode(code: string) : Promise<IResponse<PromocodeModel>> {
        return this.api.get<PromocodeModel>(apisettingUrl + "/getPromocodeByCode/" + code).toPromise();
    }
    
    public getClientSettigs(): Promise<IResponse<ClientSettingsModel>> {
        return this.api.get<ClientSettingsModel>(apisettingUrl + '/client').toPromise();
    }
}