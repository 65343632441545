<div id="modal-window" class="row">
  <div class="col card card-modal-bg-color mx-auto max-w-400">
      <div *ngIf="showTitle" class="row card-header t-center">
          <div class="col">
            <h4 class="text-uppercase c-white">{{title}}</h4>
          </div>
        
          <!-- крестик у модалки, может понадобится -->
          <!-- <div class="col">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
      </div>
      <div class="row card-body">
        <div class="col">
          <div class="row mb-3 text-center" [hidden]="!descriptionHTML">
            <div class="col">
              <label [innerHtml]="descriptionHTML" class="c-white"></label>
            </div>
          </div>

          <hr *ngIf="showButtons">

          <div *ngIf="showButtons" class="row justify-content-center text-center">
            <div *ngIf="showDeclineButton" class="col">
              <button data-target="#modal-confirm" data-toggle="modal" class="btn btn-outline-info w-100 h-100" type="button"
                (click)="isConfirm(false)"
                [ngStyle]="{'font-size': buttonDeclineFontSize}">
                {{buttonDecline | lang}}</button>
          </div>
            <div *ngIf="showConfirmButton" class="col">
                <button data-target="#modal-confirm" data-toggle="modal" class="btn btn-primary w-100" type="button"
                  (click)="isConfirm(true)">
                  {{buttonConfirm | lang}}</button>
            </div>
            <div *ngIf="showErrorButton" class="col">
                <button data-target="#modal-confirm" data-toggle="modal" class="btn btn-danger" type="button" 
                  (click)="isConfirm(true)">
                  {{buttonError | lang}}</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
